/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import { lazy } from 'react';
import { Route } from 'react-router-dom';
import AppBanner from 'smart-app-banner-react';

import YBChatbot from 'Component/YBChatbot';
import {
    CmsPage,
    CookiePopup,
    Footer,
    Router as SourceRouter,
    withStoreRegex
} from 'SourceComponent/Router/Router.component';
import isMobile, { isApp } from 'Util/Mobile/isMobile';

import {
    AFTER_ITEMS_TYPE,
    COOKIE_POPUP,
    GEOIP_POPUP,
    SWITCH_ITEMS_TYPE,
    YB_POPUP_BUILDER
} from './Router.config';

import './Router.override.style';

export {
    withStoreRegex
};

export const SearchTabPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "searchtab" */ 'Route/SearchTabPage'));
export const StoresPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "stores" */ 'Route/StoresPage'));
export const ScanPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "scan" */ 'Route/ScanPage'));
export const CustomerSettingsPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "customerSettings" */ 'Route/CustomerSettingsPage'));
export const CustomerPointsPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "customerPoints" */ 'Route/CustomerPointsPage'));
export const CustomerNotificationsPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "customerNotifications" */ 'Route/CustomerNotificationsPage'));
export const RmaPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "rma" */ 'Route/Rma'));
export const GuestRmaPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "guestrma" */ 'Route/GuestRma'));
export const NewRmaPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "newrma" */ 'Route/NewRma'));
export const AboutUsPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "aboutUs" */ 'Route/AboutUsPage'));
export const YBTiendasWholesale = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "wholesale" */ 'Route/YBTiendasWholesale'));
export const YBStoreSwitcherPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "storeSwitcher" */ 'Route/YBStoreSwitcherPage'));
export const NewsletterPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "newsletter" */ 'Route/NewsletterPage'));
export const BlogHorarios = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "blogHorarios" */ 'Route/BlogHorarios'));
export const BlogPosts = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "blogPost" */ 'Component/BlogPosts'));
export const FaqsPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "faq" */ 'Route/FaqsPage'));
export const MyGiftCardsPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "giftCard" */ 'Route/MyGiftCardsPage'));
export const CustomerMenuPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "customerMenu" */ 'Route/CustomerMenuPage'));
export const YBAsesoramientoPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "asesoramiento" */ 'Route/YBAsesoramientoPage'));
export const YBModAsesoramientoPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "asesoramientoMod" */ 'Route/YBModAsesoramientoPage'));
export const YBSiteMap = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "siteMap" */ 'Route/YBSiteMap'));
export const RmaPayment = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "rmaPayment" */ 'Route/RmaPayment'));
export const YBPopupBuilder = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "popupBuilder" */ 'Component/YBPopupBuilder'));
export const RmaGuestPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "rmaguest" */ 'Route/RmaGuestPage'));
export const YBGeoipPopup = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "notice" */ 'Component/YBGeoipPopup'));
export const CmsBlock = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cmsBlock" */ 'Component/CmsBlock'));
export const CmsCategoriesPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "categories" */ 'Route/CmsCategoriesPage'));
export const InvitePointsPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "customerPoints" */ 'Route/InvitePointsPage'));

/** @namespace Elganso/Component/Router/Component */
export class RouterComponent extends SourceRouter {
    [SWITCH_ITEMS_TYPE] = [
        ...this.SWITCH_ITEMS_TYPE,
        {
            component: <Route path={ withStoreRegex('/searchtab') } exact render={ (props) => <SearchTabPage { ...props } /> } />,
            position: 11
        },
        {
            component: <Route path={ withStoreRegex('/storespage') } exact render={ (props) => <StoresPage { ...props } /> } />,
            position: 12
        },
        {
            component: <Route path={ withStoreRegex('/scanner') } exact render={ (props) => <ScanPage { ...props } /> } />,
            position: 13
        },
        {
            component: <Route path={ withStoreRegex('/customer/settings') } render={ (props) => <CustomerSettingsPage { ...props } /> } />,
            position: 14
        },
        {
            component: <Route path={ withStoreRegex('/customer/points') } render={ (props) => <CustomerPointsPage { ...props } /> } />,
            position: 15
        },
        {
            component: <Route path={ withStoreRegex('/customer/notifications') } render={ (props) => <CustomerNotificationsPage { ...props } /> } />,
            position: 16
        },
        {
            component: <Route path={ withStoreRegex('/customer/rma') } render={ (props) => <RmaPage { ...props } /> } />,
            position: 17
        },
        {
            component: <Route path={ withStoreRegex('/languages') } exact render={ (props) => <YBStoreSwitcherPage { ...props } /> } />,
            position: 18
        },
        {
            component: <Route path={ withStoreRegex('/newsletter') } exact render={ (props) => <NewsletterPage { ...props } /> } />,
            position: 19
        },
        {
            component: <Route path={ withStoreRegex('/customer/newrma') } render={ (props) => <NewRmaPage { ...props } /> } />,
            position: 20
        },
        {
            component: <Route path={ withStoreRegex('/mprmasystem/guest/login') } render={ (props) => <GuestRmaPage { ...props } /> } />,
            position: 21
        },
        {
            component: <Route path={ withStoreRegex('/about-us') } exact render={ (props) => <AboutUsPage { ...props } /> } />,
            position: 22
        },
        {
            component: <Route path={ withStoreRegex('/blog') } exact render={ (props) => <BlogHorarios { ...props } /> } />,
            position: 23
        },
        {
            component: <Route path={ withStoreRegex('/blog/post/:step?') } exact render={ (props) => <BlogPosts { ...props } /> } />,
            position: 24
        },
        {
            component: <Route path={ withStoreRegex('/asesoramientoonline/meet') } render={ (props) => <YBAsesoramientoPage { ...props } /> } />,
            position: 26
        },
        {
            component: <Route path={ withStoreRegex('/asesoramientoonline/tienda') } render={ (props) => <YBModAsesoramientoPage { ...props } /> } />,
            position: 27
        },
        {
            component: <Route path={ withStoreRegex('/sitemap.html') } render={ (props) => <YBSiteMap { ...props } /> } />,
            position: 28
        },
        {
            component: <Route path={ withStoreRegex('/categories') } exact render={ (props) => <CmsCategoriesPage { ...props } /> } />,
            position: 29
        },
        {
            component: <Route path={ withStoreRegex('/customer/menu') } render={ (props) => <CustomerMenuPage { ...props } /> } />,
            position: 77
        },
        {
            component: <Route path={ withStoreRegex('/faq') } render={ (props) => <FaqsPage { ...props } /> } />,
            position: 78
        },
        {
            component: <Route path={ withStoreRegex('/tiendas-wholesale') } render={ (props) => <YBTiendasWholesale { ...props } /> } />,
            position: 79
        },
        {
            component: <Route path={ withStoreRegex('/customer/my-gift-cards') } render={ (props) => <MyGiftCardsPage { ...props } /> } />,
            position: 85
        },
        {
            component: <Route path={ withStoreRegex('/valoracion-pedido-tienda') } render={ (props) => <CmsPage { ...props } pageIds={ 338 } /> } />,
            position: 86
        },
        {
            component: <Route path={ withStoreRegex('/mprmasystem/payment/paypal') } render={ (props) => <RmaPayment { ...props } payment_method="paypal" /> } />,
            position: 87
        },
        {
            component: <Route path={ withStoreRegex('/mprmasystem/customer/rma/id/:rmaId?') } render={ (props) => <RmaPage { ...props } /> } />,
            position: 88
        },
        {
            component: <Route path={ withStoreRegex('/mprmasystem/guest/rma/id/:rmaId?') } render={ (props) => <RmaGuestPage { ...props } /> } />,
            position: 89
        },
        {
            component: <Route path={ withStoreRegex('/descarga-app-qr') } render={ () => this.redirectToStoreApp() } />,
            position: 90
        },
        {
            component: <Route path={ withStoreRegex('/customer/invita') } render={ (props) => <InvitePointsPage { ...props } /> } />,
            position: 91
        }
    ];

    [AFTER_ITEMS_TYPE] = [
        {
            component: <CookiePopup />,
            position: 20,
            name: COOKIE_POPUP
        },
        {
            component: <Route render={ (props) => <YBPopupBuilder { ...props } /> } />,
            position: 21,
            name: YB_POPUP_BUILDER
        },
        {
            component: <YBGeoipPopup />,
            position: 22,
            name: GEOIP_POPUP
        }
    ];

    renderSmartBanner() {
        const logoApp = 'https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/fc/70/70/fc707032-083c-a667-317a-19b083f16a7e/AppIcon-0-0-1x_U007emarketing-0-7-0-P3-85-220.png/230x0w.webp';
        const buttonText = __('Install');
        const isBannerShowed = !!localStorage.getItem('showedAppBanner');

        const appGooglePlay = {
            imageUrl: logoApp,
            name: 'ElGanso',
            publisher: 'ElGanso',
            infoText: __('Install - In Google Play'),
            linkButtonText: buttonText,
            linkUrl: 'https://play.google.com/store/apps/details?id=com.elganso.store&hl=es&gl=US'
        };

        if (!isApp() && !isBannerShowed) { // La web sin contar la app
            if (isMobile.any()) { // Unicamente movil
                if (!isMobile.iOS() && !isMobile.safari()) { // Que no sea Safari o dispositivos iOS
                    return (
                        <div className="elganso-app-banner">
                            <AppBanner
                              appInfo={ appGooglePlay }
                              position="top"
                              onClose={ () => localStorage.setItem('showedAppBanner', true) }
                              hidden={ false }
                              style={ {
                                  zIndex: 10
                              } }
                            />
                        </div>
                    );
                }
            }
        }

        return null;
    }

    redirectToStoreApp() {
        if (!isApp()) {
            if (isMobile.any()) { // Unicamente movil
                if (!isMobile.iOS()) { // Que no sea Safari o dispositivos iOS
                    window.location.href = 'https://play.google.com/store/apps/details?id=com.elganso.store&hl=es&gl=US';
                } else {
                    window.location.href = 'https://apps.apple.com/es/app/el-ganso/id1173967862';
                }
            }
        }

        return null;
    }

    renderYBChatbot() {
        return (
            <YBChatbot />
        );
    }

    renderDefaultRouterContent() {
        const { isOnlyMainItems } = this.props;

        if (isOnlyMainItems) {
            return this.renderMainItems();
        }

        return (
            <>
                <div
                  block="Router"
                  elem="MainItems"
                  className={ isApp() ? 'isApp' : '' }
                >
                    { this.renderMainItems() }
                    <Footer />
                    <CmsBlock identifier="css-pwa" />
                    { this.renderYBChatbot() }
                </div>
                { this.renderSectionOfType(AFTER_ITEMS_TYPE) }
                { this.renderSmartBanner() }
            </>
        );
    }
}

export default RouterComponent;
