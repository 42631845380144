/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

/**
 * CustomerNotifications Mutations
 * @class CustomerNotifications
 * @namespace Elganso/Query/CustomerNotifications/Query */
export class CustomerNotificationsQuery {
    getNotificationsQuery() {
        return new Field('getNotifications')
            .addFieldList(this._getNotificationsFields());
    }

    _getNotificationsFields() {
        return [
            'id',
            'message',
            'icon',
            'date',
            'time',
            'link',
            'readed'
        ];
    }

    getMarcarNotificacionLeidoMutation(options) {
        return new Field('marcarNotificacionLeido')
            .addArgument('input', 'NotificationCheckInput!', options);
    }
}

export default new CustomerNotificationsQuery();
